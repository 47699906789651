import React from "react";
import Marquee from "react-fast-marquee";
import CoolIshr from "./assets/Cool-Ishrae-e1715666840667-2048x717.png";
import ACREX from "./assets/ACREX-2025-logo-with-date-venue-new-01.png";
import Peda from "./assets/logopde.png";
import MANU from "./assets/MANU.jpg";
import WefixLogo11 from "./assets/logo10.png";
import LogoD2o from "./assets/logoD2o.webp"; 
import LogoDIAS from "./assets/logoDIAS.svg";
import RajisthanLOgo11 from "./assets/Rj.jpeg";
import Griha from "./assets/griha.jpg";
import "./Marquee.css";  

const MarqueeImg = () => {
  return (
    
   
      <div className="marquee-container">
        <Marquee
          speed={30}
          direction="left"
          delay={0.2}
        >
          <img src={CoolIshr} alt="Cool Ishr" className="marquee-image" />
          <img src={ACREX} alt="ACREX" className="marquee-image" />
          <img src={Peda} alt="Peda" className="marquee-image" />
          <img src={MANU} alt="MANU" className="marquee-image" />
          <img src={WefixLogo11} alt="WeFix Logo" className="marquee-image" />
          <img
            src={RajisthanLOgo11}
            alt="Rising Rajasthan Logo"
            className="marquee-image"
          />
          <img
            src={LogoDIAS}
            alt="Logo DIAS"
            className="marquee-image"
          />
          <img
            src={Griha}
            alt="Logo Griha"
            className="marquee-image"
          />
          <img
            src={LogoD2o}
            alt="Logo D2o"
            className="marquee-image"
          />
        </Marquee>
      </div>
      

      
    
  );
};

export default MarqueeImg;
