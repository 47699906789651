import React, { useState, useEffect } from "react";
import "./NewAddInfoPopup.css";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import ReactSpeedometer from "react-d3-speedometer";
import ecologo from "./assets/ecologo.png";

import {
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
   InstapaperIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";


function NewAddInfoPopup({ emissionData, onClose, formState, id }) {
  const [isShareCardVisible, setShareCardVisible] = useState(false);
  const shareURL = `https://ecoindex.octodosolutions.com/newaddinfo/${id}`;

     useEffect(() => {
        if (id) {
          console.log(`Received ID: ${id}`);
          // You can fetch or handle data based on the ID here if necessary.
        }
      }, [id]);

  if (!emissionData) return null;

  const eventName = formState?.EventName || "Unknown Event";
  const travelDetails = formState?.TravelDetails || [];
  const lastToLoc =
    travelDetails.length > 0
      ? travelDetails[travelDetails.length - 1].ToLoc
      : "Unknown Location";

   

  const toggleShareCard = () => {
    setShareCardVisible((prev) => !prev);
  };

 
  const handleCaptureClick = async () => {
    const popupElement = document.querySelector(".popup-container");
    const footerElement = popupElement.querySelector(".popup-footer");
  
    // Temporarily apply a class for screenshot adjustments
    popupElement.classList.add("screenshot-mode");
    footerElement.classList.add("hidden");
  
    const canvas = await html2canvas(popupElement, {
      backgroundColor: null,
      scale: 3,
    });
  
    // Remove the temporary class
    popupElement.classList.remove("screenshot-mode");
    footerElement.classList.remove("hidden");
  
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "popup-body-snippet.png", "image/png");
  };
  
  
  

  const totalEmission =
    (emissionData.totalTravelEmission || 0) +
    (emissionData.totalAccommodationEmission || 0) +
    (emissionData.totalMealEmission || 0);

  const travelPercentage =
    totalEmission > 0
      ? ((emissionData.totalTravelEmission || 0) / totalEmission) * 100
      : 0;
  const accommodationPercentage =
    totalEmission > 0
      ? ((emissionData.totalAccommodationEmission || 0) / totalEmission) * 100
      : 0;
  const foodPercentage =
    totalEmission > 0
      ? ((emissionData.totalMealEmission || 0) / totalEmission) * 100
      : 0;

    
      let emissionMessage = "";
      let additionalMessage = "";
      let messageStyle = {};


if (totalEmission <= 80) {
  emissionMessage =
    "Great job! Your emissions are below the average for conference attendees. Keep up the good work.";
  messageStyle = { color: "" };
} else if (totalEmission <= 160) {
  emissionMessage =
    "Well done! Your emissions are in line with the average for conference attendees. Every step counts toward sustainability!";
  messageStyle = { color: "" };
} else {
  // High emissions range
  emissionMessage =
    "Oops! Your emissions are higher than the average for conference attendees. ";
  messageStyle = { color: "" };

  // Additional messages for specific conditions
  if (travelPercentage > 50) {
    additionalMessage =
      "Try switching to greener transport options for the next event.";
  } else if (accommodationPercentage > 50) {
    additionalMessage =
      "Try switching to greener stay options for the next event.";
  }
}


  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <img src={ecologo} alt="" srcset="" />
          <p>Your Emission</p>
          <button className="popup-close" onClick={onClose}>
            x
          </button>
        </div>

        <div className="popup-body">
          <div className="emission-summary">
            <div className="emission-summary-details">
              <p>Total Emission</p>
              <h1 className="total-emission">
                {" "}
                <b>{emissionData.totalEmission || 0}</b> Kg CO₂
              </h1>
              
              <div className="bar-container">
                
                <div className="skill-box">
                  <span className="bar-title">Accommodation: <b>{emissionData.totalAccommodationEmission || 0}</b> KgCO₂</span>
                  <div className="skill-bar">
                    <span
                      className="skill-per "
                      style={{
                        width: `${accommodationPercentage}%`,
                        background: "#FFD5F7",
                      }}
                    >
                  
                      <span className="bar-text">
                        {accommodationPercentage.toFixed(1)}%
                      </span>
                      
                    </span>
                  </div>
                </div>

                 
                <div className="skill-box">
                  <span className="bar-title">Travel: <b>{emissionData.totalTravelEmission || 0}</b> KgCO₂</span>
                  <div className="skill-bar">
                    <span
                      className="skill-per "
                      style={{
                        width: `${travelPercentage}%`,
                        background: "#D7EBFB",
                      }}
                    >
                    
                      <span className="bar-text">
                        {travelPercentage.toFixed(1)}%
                      </span>
                    </span>
                  </div>
                </div>

                 
                <div className="skill-box">
                  <span className="bar-title">Food: <b>{emissionData.totalMealEmission || 0}</b> KgCO₂</span>
                  <div className="skill-bar">
                    <span
                      className="skill-per "
                      style={{
                        width: `${foodPercentage}%`,
                        background: "#F9F5C7",
                      }}
                    >
                      
                      <span className="bar-text">
                        {foodPercentage.toFixed(1)}%
                      </span>
                    </span>
                  </div>
                </div>

             


          
              </div>
            </div>

            <div className="emission-summary-meter">
              <div>
                <ReactSpeedometer
                  width={270}
                  height={180}
                  needleTransitionDuration={1000}
                  needleColor="black"
                  minValue={0}
                  maxValue={240}
                  segments={3}
                  segmentColors={["#4dbce8", "#ffa342", "#f00018"]}
                  customSegmentStops={[0, 80, 160, 240]}
                  labelFontSize={"0px"}
                  valueTextFontSize={"0px"}
                  value={emissionData.totalEmission || 0}
                />
              </div>
              <div className="popup-text">
    <p style={messageStyle}>
      
      <strong style={{ fontSize: "14px" }}>
        {emissionMessage.split(".")[0]}.
      </strong>
     
      <br />
      {emissionMessage.split(".").slice(1).join(".")}
    </p>
    {additionalMessage && (
      <p style={{ fontStyle: "italic", marginTop: "10px" }}>
        {additionalMessage}
      </p>
    )}
  </div>
            </div>
          </div>

          <div className="info-event">
    <div className="info-event-details">
        <h4>Event Details</h4>
        <p>
        <b>Name: </b>{eventName} <br/> <b>Location: </b>{lastToLoc}
        </p>
    </div>
</div>
        </div>

        <div className="popup-footer">
          <div>
            <button className="sign-up-button">Sign Up</button>
            <button className="download-button" onClick={handleCaptureClick}>
              Download
            </button>
          </div>

          <div className="share-container">
            <button className="share-button" onClick={toggleShareCard}>
              <i
                className="fa fa-share-alt"
                style={{ color: "white", marginRight: "10px" }}
              ></i>
              Share
            </button>

            {isShareCardVisible && (
              <div className="share-card">
                < InstapaperShareButton url={shareURL} quote="Check out my emissions summary!" >
                  < InstapaperIcon size={32} round={true} />
                  </InstapaperShareButton>

                <LinkedinShareButton url={shareURL} title="My Emissions Summary">
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>

                <TwitterShareButton url={shareURL} title="My Emissions Summary">
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>

                <WhatsappShareButton url={shareURL} title="My Emissions Summary">
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAddInfoPopup;
